<template>
  <div v-if="userDataFetched" class="container">
    <h1>Välkommen {{ firstName }}</h1>
    Här kan du ändra dina inställningar.
    <div class="user__container">
      <div class="user__avatar">
        <img width="300" :src="avatar" ref="avatarImage" :alt="`${firstName}s porträtt`" />
        <input
          type="file"
          name="avatar"
          ref="avatarUpload"
          id="avatarImage"
          accept="image/png, image/jpeg"
          placeholder="Ladda upp"
          class="avatar__button"
          @change="uploadSlide('avatarUpload')"
        />
      </div>
      <div class="user__data">
        <label for="firstname">Förnamn</label>
        <input
          type="text"
          name="firstname"
          :value="firstName"
          ref="firstName"
        />
        <label for="lastname">Efternamn</label>
        <input type="text" name="lastname" :value="lastName" ref="lastName" />
        <label for="email">Epost</label>
        <input type="text" name="email" :value="eMail" ref="eMail" />
        <label for="phone">Telefonnummer</label>
        <input type="tel" name="phone" :value="phone" ref="phone" />
        <label for="phone">Befattning</label>
        <input type="text" name="title" :value="title" ref="title" />
      </div>
      <div class="user__buttoncontainer">
        <button class="btn" @click="updateUserData">Spara</button>
      </div>
    </div>
  </div>
  <div v-else class="container">
    <h1>Välkommen, laddar...</h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as fb from "../firebase";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUserData", "getUid"]),
    firstName() {
      return this.getUserData.firstName;
    },
    lastName() {
      return this.getUserData.lastName;
    },
    eMail() {
      return this.getUserData.eMail;
    },
    phone() {
      return this.getUserData.phone;
    },
    // avatar() {
    //   return this.getUserData.avatar;
    // },
    avatar: {
      get() {
        return this.getUserData.avatar;
      },
      set(newUrl) {
        return newUrl;
      }
    },
    title() {
      return this.getUserData.title;
    },
    userDataFetched() {
      return this.getUserData && Object.keys(this.getUserData).length !== 0;
    }
  },
  methods: {
    updateUserData() {
      let uid = this.getUid;
      fb.db.collection("users").doc(uid).update({
        firstName: this.$refs.firstName.value,
        lastName: this.$refs.lastName.value,
        phone: this.$refs.phone.value,
        eMail: this.$refs.eMail.value,
        title: this.$refs.title.value,
        avatar: this.$refs.avatarImage.src
      });
    },
    uploadSlide(ref) {
      var storage = fb.storage;
      var storageRef = storage.ref();
      var file = this.$refs[ref].files[0];
      var fileType = file.type;
      var metadata = {
        contentType: fileType,
      };
      var uploadTask = storageRef
        .child("useravatars/" + this.makeid(3) + file.name)
        .put(file, metadata);
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            //console.log("File available at", downloadURL);
            {
              this.$refs.avatarImage.src = downloadURL;
            }
          });
        }
      );
    },
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
.user__container {
  margin: 1rem auto;
  max-width: 40rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  .user__avatar {
    img {
      width: 100%;
      display: block;
    }
  }
  .user__data {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  label {
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    margin-bottom: 0.4rem;
    color: #777;
  }
  input {
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
  }
  .user__buttoncontainer {
    grid-column: 1 /3;
    text-align: right;
    .btn {
      margin-right: 0;
    }
  }
}
</style>
